import guildexperience from '@/components/guildexperience'
import leaderboards from '@/components/leaderboards'
import toolbox from '@/components/toolbox'
import knowledgebase from '@/components/knowledgebase'
import shop from '@/components/shop'
import calendar from '@/components/calendar'
import stats from '@/components/stats'
import gametracker from '@/components/gametracker'
import giveaway from '@/components/giveaway'
import admin from '@/components/admin/dashboard'
import officer from '@/components/officer/dashboard'
import expsaturday from '@/components/events/expsaturday'
import { createRouter, createWebHistory } from 'vue-router'


const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
      path: '/',
      name: 'Dashboard',
      component:guildexperience
      },
      {
      path: '/leaderboards',
      name: 'Leaderboards',
      component:leaderboards
      },
      {
      path: '/toolbox',
      name: 'Toolbox',
      component:toolbox
      },
      {
      path: '/knowledgebase',
      name: 'Knowledgebase',
      component:knowledgebase
      },
      {
      path: '/shop',
      name: 'Guild Shop',
      component:shop
      },
      {
      path: '/calendar',
      name: 'Calendar',
      component:calendar
      },
      {
      path: '/stats',
      name: 'Hypixel Stats',
      component:stats
      },
      {
      path: '/expsaturday',
      name: 'Guild Exp Event',
      component:expsaturday
      },
      {
      path: '/gametracker',
      name: 'Game Tracker',
      component:gametracker
      },
      {
      path: '/giveaway',
      name: 'Giveaway',
      component:giveaway
      },
      {
      path: '/admin',
      name: 'Admin Dashboard',
      component:admin
      },
      {
      path: '/officer',
      name: 'Officer Dashboard',
      component:officer
      },
    ]
  });

export default router