<template>
  <div class="giveaway">
  </div>
</template>

<script>

export default {
  name: 'giveaway',
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.widgetslist {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -32px -16px 0;
}
.calendar h3{
  font-size:30px;
  font-weight:500;
  text-align:center;
}
.widgetsitem {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(25% - 32px);
    flex: 0 0 calc(25% - 32px);
    max-width: calc(25% - 32px);
    margin: 32px 16px 0;
    border-radius: 24px;
    background: #242731;
    -webkit-box-shadow: 0 8px 25px rgb(0 0 0 / 7%);
    box-shadow: 0 8px 25px rgb(0 0 0 / 7%);
    color:white;
    text-align:center;
}
.widgetsbody {
    padding: 20px 24px 40px;
    text-align:center;
}
.widgetsprice {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    line-height: 1.33333;
    font-weight: 400;
}
.widgetschart {
    height: 92px;
    margin-bottom: -30px;
    margin-left: auto;
  order: 2;
}
.apexcharts-canvas {
    position: relative;
    user-select: none;
}
.resize-triggers, .resize-triggers>div, .contract-trigger:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.widgetshead {
    padding: 24px 24px 10px;
    text-align:center;
}
.widgetscompany {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.widgetscategory {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    line-height: 1.33333;
    font-weight: 500;
}
.widgetsinfo {
    font-weight: 600;
    color: #808191;
}
.shop .widgetshead img{
  border-radius:8px;
}
.expsort {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -8px auto 0 -8px;
    padding-top:12px;
}
.expsort .expsortlink {
    padding: 0 16px;
    border-radius: 20px;
    line-height: 30px;
    margin: 4px 0 0 4px;
    font-weight: 600;
    color: #808191;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}
.expsort .expsortlink.active {
    background: #191B20;
    color: #355DFF;
}
.expsortlink:hover{
  color:#FFFFFF;
  background:rgba(228, 228, 228, 0.1);
}

.apexcharts-xcrosshairs, .apexcharts-ycrosshairs{
  display:none;
}
.status {
    position: relative;
    padding-left: 18px;
    line-height: 16px;
    font-weight: 700;
}
.status.positive {
    color: #4FBF67;
}
.status:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 13px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% auto;
}
.status.positive:before {
    background: url("data:image/svg+xml,%3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.300049 6.7L1.70005 8.1L6.00005 3.8V16H8.00005V3.8L12.3 8.1L13.7 6.7L7.00005 0L0.300049 6.7Z' fill='%234FBF67'/%3E%3C/svg%3E%0A");
}
@media only screen and (max-width: 767px){
  .widgetsitem{
    max-width: 100%;
    margin: 0;
    margin-bottom:16px;
  }
  .widgetslist{
    margin:0;
    display:block;
  }
}
</style>
