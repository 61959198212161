<template>
  <div id="app">
    <sidebar/>
    <pagecontent/>
  </div>
</template>

<script>
import pagecontent from '@/components/pagecontent.vue'
import sidebar from '@/components/sidebar.vue'

export default {
  name: 'App',
  components: {
    pagecontent,
    sidebar
  }
}
</script>

<style>
#app {
  overflow: hidden;
}
</style>
