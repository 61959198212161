<template>
  <div class="pagecontent">
   <div class="header">
     <a class="headerlogo" href="index.html"><img src="@/assets/logo.svg" alt=""></a>
     <div class="headergroup">
       <div class="headeritem">
         <button class="headerhead">TeQnix</button>
         <button class="headertoggle"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
              <path d="M22 12H3" stroke="#11142d"></path>
              <g stroke="#808191">
                <path d="M22 4H13"></path>
                <path opacity=".301" d="M22 20H13"></path>
              </g>
              <path d="M7 7l-5 5 5 5" stroke="#11142d"></path>
            </svg></button>
       </div>
     </div>
   </div>
   <div class="pagecontainer">
       <div class="pagehead">
         <div class="h5 pagecategory">Spectre Guild</div>
         <div class="h2 pagetitle">{{currentRouteName}}</div>
       </div>
      <router-view/>
   </div>
  </div>
</template>

<script>

export default {
  name: 'pagecontent',
  components: {
  },
  computed: {
    currentRouteName() {
        return this.$route.name;
    }
}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pagecontent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    padding-left: 255px;
    -webkit-transition: padding .2s;
    -o-transition: padding .2s;
    transition: padding .2s;
    max-width: 1920px;
    margin: 0 auto;
}
.header {
    position: relative;
    z-index: 10;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 48px 64px 36px;
}
.headergroup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: auto;
}
.headeritem {
    position: relative;
}
.headeritem .headerhead {
    color: #ffffff;
    position: relative;
    padding-right: 22px;
    font-weight: 600;
}
.headerhead:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: 12px;
    height: 7px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' fill='none' viewBox='0 0 12 8'%3E%3Cpath d='M10.281.305L5.988 4.598 1.695.305A1 1 0 0 0 .293.317a1 1 0 0 0-.012 1.402l5 5a1 1 0 0 0 1.414 0l5-5a1 1 0 0 0-.441-1.687 1 1 0 0 0-.973.273z' fill='%23ffffff'/%3E%3C/svg%3E") no-repeat 50% 50%/auto 100%;
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    -o-transition: transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
}
.pagerow{
  display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}
.pagecol:first-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 426px);
    flex: 0 0 calc(100% - 426px);
    max-width: calc(100% - 426px);
}
.pagecol {
    padding: 0 64px 64px;
}
.pagehead {
    margin-top: -60px;
    margin-bottom: 32px;
}
.pagecontainer {
    padding: 0 64px 64px;
}
.headertoggle {
    display: none;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-left: 32px;
}
.headerlogo {
    display: none;
    margin-right: auto;
}
.headertoggle svg > path {
    stroke: #ffffff;
}
.headertoggle svg g {
    stroke: rgba(128, 129, 145, 0.7);
}
.headertoggle svg g path:nth-child(2) {
    opacity: .6;
}



@media only screen and (max-width: 1419px){
  .pagerow{
    padding: 0 64px 64px;
  }
  .pagecol{
    padding:0;
  }
  .pagecol:first-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 290px);
    flex: 0 0 calc(100% - 290px);
    max-width: calc(100% - 290px);
    padding-right: 64px;
  }
}
@media only screen and (max-width: 1339px){
  .pagecontainer {
    padding: 0 48px 64px;
  }
  .header {
    padding: 48px 48px 36px;
  }
}

@media only screen and (max-width: 1023px) {
  .pagecontent {
    padding-left: 0;
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    -o-transition: transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }
.header {
    padding: 32px 48px;
}

.headerlogo {
    display: inline-block;
}
.pagehead {
    margin-top: 0;
}
  .headertoggle {
    display: inline-block;
}
}

@media only screen and (max-width: 767px){
  .pagecontainer{
    padding: 0 16px 32px;
  }
.pagetitle {
    font-size: 32px;
}
.pagecategory {
    font-size: 18px;
}
.header {
    padding: 24px 16px;
}
}
</style>
