<template>
  <div class="guildexperience">
    <div class="widgetslist">
      <div class="widgetsitem gxptime">
        <div class="widgetshead">
          <div class="widgetscompany">
            <div class="widgetsdetails">
              <div class="widgetscategory">Your Guild Experience</div>
              <div class="status positive">+2.73%</div>
              <div class="widgetsinfo">
                <div class="expsort">
                  <a href="#" class="expsortlink guildxpowndaily">daily</a>
                  <a href="#" class="expsortlink guildxpownweekly">weekly</a>
                  <a href="#" class="expsortlink guildxpownmonthly active"
                    >monthly</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgetsbody">
          <div class="widgetsline">
            <div class="widgetsprice">4,392,249</div>
          </div>
        </div>
      </div>

      <div class="widgetsitem gxptime">
        <div class="widgetshead">
          <div class="widgetscompany">
            <div class="widgetsdetails">
              <div class="widgetscategory">Overall Guild Experience</div>
              <div class="status positive">+2.73%</div>

              <div class="widgetsinfo">
                <div class="expsort">
                  <a href="#" class="expsortlink guildxpalldaily">daily</a>
                  <a href="#" class="expsortlink guildxpallweekly">weekly</a>
                  <a href="#" class="expsortlink guildxpallmonthly active"
                    >monthly</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgetsbody">
          <div class="widgetsline">
            <div class="widgetsprice">104,249,211</div>
          </div>
        </div>
      </div>

      <div class="widgetsitem">
        <div class="widgetshead">
          <div class="widgetscompany">
            <div class="widgetsdetails">
              <div class="widgetscategory">Hypixel Guild Position</div>
            </div>
          </div>
        </div>
        <div class="widgetsbody" style="display: flex;flex-direction: column;">
          <div class="widgetsline">
            <div class="widgetsprice">
              #87 <small>302,126 exp behind #86</small>
            </div>
          </div>
        </div>
      </div>

      <div class="widgetsitemlg smission">
        <div class="widgetshead">
          <div class="widgetscompany">
            <div class="widgetsdetails">
              <div class="widgetscategory">Guild Missions</div>
              <div class="widgetsinfo">
                <p>resets in 5h 37m 20s</p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="widgetsbody spmission"
          style="display: flex;flex-direction: column;"
        >
          <div class="nmis">
            <h5>
              Earn 250,000 Guild Experience
              <button id="claimbtn">Claim Reward</button>
            </h5>
            <div class="progress">
              <div
                class="progress-bar bg-success"
                role="progressbar"
                style="width: 100%"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div class="missionprogress">
              258,582 / 250,000 exp <span>400 point reward</span>
            </div>
          </div>
          <div class="nmis">
            <h5>Earn 500,000 Guild Experience</h5>
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                style="width: 10%"
                aria-valuenow="10"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div class="missionprogress">
              48,289 / 500,000 exp <span>400 point reward</span>
            </div>
          </div>
          <div class="nmis">
            <h5>Earn 750,000 Guild Experience</h5>
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                style="width: 7.5%"
                aria-valuenow="7.5"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div class="missionprogress">
              48,289 / 750,000 exp <span>400 point reward</span>
            </div>
          </div>
          <div class="nmis">
            <h5>Earn 1,000,000 Guild Experience</h5>
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                style="width: 5%"
                aria-valuenow="5"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div class="missionprogress">
              48,289 / 1,000,000 exp <span>400 point reward</span>
            </div>
          </div>
        </div>
      </div>

      <div class="widgetsitemlg">
        <div class="widgetshead">
          <div class="widgetscompany">
            <div class="widgetsdetails">
              <div class="widgetscategory">My Spectre Stats</div>
            </div>
          </div>
        </div>
        <div class="widgetsbody" style="display: flex;flex-direction: column;">
          
        </div>
      </div>
    </div>
    <p id="lastUpdated">last updated 13:47</p>
  </div>
</template>

<script>
export default {
  name: "guildexperience",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.widgetslist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -32px -16px 0;
}
.widgetsitem {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(33.33% - 32px);
  flex: 0 0 calc(33.33% - 32px);
  max-width: calc(33.33% - 32px);
  margin: 32px 16px 0;
  border-radius: 24px;
  background: #242731;
  -webkit-box-shadow: 0 8px 25px rgb(0 0 0 / 7%);
  box-shadow: 0 8px 25px rgb(0 0 0 / 7%);
}

.widgetsitemlg {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 32px);
  flex: 0 0 calc(50% - 32px);
  max-width: calc(50% - 32px);
  margin: 32px 16px 0;
  border-radius: 24px;
  background: #242731;
  -webkit-box-shadow: 0 8px 25px rgb(0 0 0 / 7%);
  box-shadow: 0 8px 25px rgb(0 0 0 / 7%);
}

.widgetsbody {
  padding: 24px;
  text-align: center;
  width: 100%;
}
.guildexperience small {
  display: block;
  opacity: 0.7;
  font-size: 14px;
}
.widgetsline {
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 12px;
}
.widgetsprice {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  line-height: 1.33333;
  font-weight: 500;
}
.widgetschart {
  height: 92px;
  margin-bottom: -30px;
  margin-left: auto;
  order: 2;
}
.apexcharts-canvas {
  position: relative;
  user-select: none;
}
.resize-triggers,
.resize-triggers > div,
.contract-trigger:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.widgetshead {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid rgba(228, 228, 228, 0.1);
}
.widgetscompany {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.widgetscategory {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
}
.widgetsinfo {
  font-weight: 600;
  color: #808191;
}
.expsort {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -8px auto 0 -8px;
  padding-top: 12px;
}
.expsort .expsortlink {
  padding: 0 16px;
  border-radius: 20px;
  line-height: 30px;
  margin: 4px 0 0 4px;
  font-weight: 600;
  color: #808191;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.expsort .expsortlink.active {
  background: #191b20;
  color: #355dff;
}
.expsortlink:hover {
  color: #ffffff;
  background: rgba(228, 228, 228, 0.1);
}

.apexcharts-xcrosshairs,
.apexcharts-ycrosshairs {
  display: none;
}
.status {
  position: relative;
  padding-left: 18px;
  line-height: 16px;
  font-weight: 700;
}
.status.positive {
  color: #4fbf67;
}
.status:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 13px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% auto;
}
.status.positive:before {
  background: url("data:image/svg+xml,%3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.300049 6.7L1.70005 8.1L6.00005 3.8V16H8.00005V3.8L12.3 8.1L13.7 6.7L7.00005 0L0.300049 6.7Z' fill='%234FBF67'/%3E%3C/svg%3E%0A");
}
.gxptime .widgetscategory {
  float: left;
}
.gxptime .status {
  float: right;
}
.gxptime .widgetsinfo {
  clear:both;
  float: left;
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width 0.6s ease;
}
.progress {
  display: flex;
  height: 6px;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
#lastUpdated {
  text-align: center;
  padding-top: 15px;
  font-size: 12px;
  color: #686d80;
}
.spmission {
  text-align: left;
}
.spmission .nmis {
  padding-bottom: 20px;
}
.spmission .nmis h5 {
  font-weight: 500;
  padding-bottom: 5px;
  font-size: 15px;
}
.spmission .nmis .missionprogress {
  color: #808191;
  padding-top: 5px;
}
.spmission .nmis .missionprogress span {
  float: right;
}
.smission .widgetshead {
  padding-bottom: 10px;
}
.gxptime .widgetscompany, .gxptime .widgetscompany .widgetsdetails {
    width: 100%;
}
.bg-success {
  background-color: #4fbf67 !important;
}
#claimbtn {
  padding: 4px 8px;
  background: #4fbf67;
  border-radius: 4px;
  color: #1f2128;
  float: right;
}
@media only screen and (max-width: 767px) {
  .widgetsitem {
    max-width: 100%;
    margin: 0;
    margin-bottom: 16px;
  }
  .widgetslist {
    margin: 0;
    display: block;
  }
}
</style>
