<template>
  <div class="gametracker">
        <div class="widgetslist">
      <div class="widgetsitemlg">
        <div class="widgetsbody">
            <div class="widgetsprice settingpanel">
              <div class="counter">heyyy</div>
              <div class="buttons">
              <button class="settings">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
  <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>
  <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>
</svg>
Settings
              </button>

              <button class="reset">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
  <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
</svg>
Reset
              </button>
              </div>
              </div></div>
      </div>
      <div class="widgetsitem">
        <div class="widgetshead">
          <div class="widgetscompany">
            <div class="widgetsdetails">
              <div class="widgetscategory">Wins</div>
            </div>
          </div>
        </div>
        <div class="widgetsbody">
          <div class="widgetsprice">40</div>
        </div>
      </div>

      <div class="widgetsitem">
        <div class="widgetshead">
          <div class="widgetscompany">
            <div class="widgetsdetails">
              <div class="widgetscategory">W/L</div>
            </div>
          </div>
        </div>
        <div class="widgetsbody">
          <div class="widgetsprice">40.00</div>
        </div>
      </div>
      
            <div class="widgetsitem">
        <div class="widgetshead">
          <div class="widgetscompany">
            <div class="widgetsdetails">
              <div class="widgetscategory">Kills</div>
            </div>
          </div>
        </div>
        <div class="widgetsbody">
          <div class="widgetsprice">272</div>
        </div>
      </div>

            <div class="widgetsitem">
        <div class="widgetshead">
          <div class="widgetscompany">
            <div class="widgetsdetails">
              <div class="widgetscategory">Finals</div>
            </div>
          </div>
        </div>
        <div class="widgetsbody">
          <div class="widgetsprice">213</div>
        </div>
      </div>

            <div class="widgetsitem">
        <div class="widgetshead">
          <div class="widgetscompany">
            <div class="widgetsdetails">
              <div class="widgetscategory">FKDR</div>
            </div>
          </div>
        </div>
        <div class="widgetsbody">
          <div class="widgetsprice">213.00</div>
        </div>
      </div>

      </div>
  </div>
</template>

<script>

export default {
  name: 'gametracker',
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.widgetslist {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -32px -16px 0;
}
.calendar h3{
  font-size:30px;
  font-weight:500;
  text-align:center;
}
.widgetsitemlg {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 32px);
    flex: 0 0 calc(100% - 32px);
    max-width: calc(100% - 32px);
    margin: 32px 16px 0;
    border-radius: 24px;
    background: #242731;
    -webkit-box-shadow: 0 8px 25px rgb(0 0 0 / 7%);
    box-shadow: 0 8px 25px rgb(0 0 0 / 7%);
    color:white;
}

.widgetsitem {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(20% - 32px);
    flex: 0 0 calc(20% - 32px);
    max-width: calc(20% - 32px);
    margin: 32px 16px 0;
    border-radius: 24px;
    background: #242731;
    -webkit-box-shadow: 0 8px 25px rgb(0 0 0 / 7%);
    box-shadow: 0 8px 25px rgb(0 0 0 / 7%);
    text-align:center;
    color:white;
}
.widgetsbody {
    padding: 20px 24px 20px;
}
.widgetsprice {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    line-height: 1.33333;
    font-weight: 400;
}
.widgetshead {
    padding: 24px 24px 10px;
    text-align:center;
    border-bottom: 1px solid rgba(228, 228, 228, 0.1);
}
.widgetscategory {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    line-height: 1.33333;
    font-weight: 500;
    text-align:center;
}
.widgetsinfo {
    font-weight: 600;
    color: #808191;
}
.shop .widgetshead img{
  border-radius:8px;
}
.expsort {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -8px auto 0 -8px;
    padding-top:12px;
}
.expsort .expsortlink {
    padding: 0 16px;
    border-radius: 20px;
    line-height: 30px;
    margin: 4px 0 0 4px;
    font-weight: 600;
    color: #808191;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}
.expsort .expsortlink.active {
    background: #191B20;
    color: #355DFF;
}
.expsortlink:hover{
  color:#FFFFFF;
  background:rgba(228, 228, 228, 0.1);
}

.apexcharts-xcrosshairs, .apexcharts-ycrosshairs{
  display:none;
}
.status {
    position: relative;
    padding-left: 18px;
    line-height: 16px;
    font-weight: 700;
}
.status.positive {
    color: #4FBF67;
}
.status:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 13px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% auto;
}
.status.positive:before {
    background: url("data:image/svg+xml,%3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.300049 6.7L1.70005 8.1L6.00005 3.8V16H8.00005V3.8L12.3 8.1L13.7 6.7L7.00005 0L0.300049 6.7Z' fill='%234FBF67'/%3E%3C/svg%3E%0A");
}

button.settings{
  background:#7a7a7a;
  padding:10px 20px;
  border-radius:8px;
  color:white;
  font-weight:500;
  font-size:16px;
  margin-right:10px;
  cursor:pointer;
}

button.reset{
  background:#ec4933;
  padding:10px 20px;
  border-radius:8px;
  color:white;
  font-weight:500;
  font-size:16px;
  cursor:pointer;
}
button.settings svg, button.reset svg{
  margin-bottom: -2px;
}
.buttons{
  margin-left: auto;
}
.counter{
}
.settingpanel{
  display: flex;
}

@media only screen and (max-width: 767px){
  .widgetsitem{
    max-width: 100%;
    margin: 0;
    margin-bottom:16px;
  }
  .widgetslist{
    margin:0;
    display:block;
  }
}
</style>
