<template>
  <div class="guildexperience">
    <div class="widgetslist">



      <a href="#" class="widgetsitem">
        <div class="widgetshead">
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"/>
</svg>
</div>
        <div class="widgetsbody">
            <div class="widgetsprice">Guild<br/> Members</div></div>
      </a>

      <a href="#" class="widgetsitem">
        <div class="widgetshead">
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-person-check" viewBox="0 0 16 16">
  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
  <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
</svg>
</div>
        <div class="widgetsbody">
            <div class="widgetsprice">Active Checker</div></div>
      </a>


      <a href="#" class="widgetsitem">
        <div class="widgetshead">
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-hand-thumbs-up" viewBox="0 0 16 16">
  <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
</svg>
</div>
        <div class="widgetsbody">
            <div class="widgetsprice">Member Commends</div></div>
      </a>

      <a href="#" class="widgetsitem">
        <div class="widgetshead">
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="icon" viewBox="0 0 16 16">
  <path d="M4.5 11a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM3 10.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
  <path d="M16 11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V9.51c0-.418.105-.83.305-1.197l2.472-4.531A1.5 1.5 0 0 1 4.094 3h7.812a1.5 1.5 0 0 1 1.317.782l2.472 4.53c.2.368.305.78.305 1.198V11zM3.655 4.26L1.592 8.043C1.724 8.014 1.86 8 2 8h12c.14 0 .276.014.408.042L12.345 4.26a.5.5 0 0 0-.439-.26H4.094a.5.5 0 0 0-.44.26zM1 10v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1z"/>
</svg>
</div>
        <div class="widgetsbody">
            <div class="widgetsprice">Server<br/> Status</div></div>
      </a>

    </div>
  </div>
</template>

<script>

export default {
  name: 'toolbox',
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.widgetslist {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -32px -16px 0;
}
.widgetsitem {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(20% - 32px);
    flex: 0 0 calc(20% - 32px);
    max-width: calc(20% - 32px);
    margin: 32px 16px 0;
    border-radius: 24px;
    background: #242731;
    -webkit-box-shadow: 0 8px 25px rgb(0 0 0 / 7%);
    box-shadow: 0 8px 25px rgb(0 0 0 / 7%);
    color:white;
    text-align:center;
}
.widgetsbody {
    padding: 0 24px 40px;
    text-align:center;
}
.widgetsprice {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    line-height: 1.33333;
    font-weight: 400;
}
.widgetschart {
    height: 92px;
    margin-bottom: -30px;
    margin-left: auto;
  order: 2;
}
.apexcharts-canvas {
    position: relative;
    user-select: none;
}
.resize-triggers, .resize-triggers>div, .contract-trigger:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.widgetshead {
    padding: 40px 24px 10px;
    text-align:center;
}
.widgetscompany {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.widgetscategory {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    line-height: 1.33333;
    font-weight: 500;
}
.widgetsinfo {
    font-weight: 600;
    color: #808191;
}
.expsort {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -8px auto 0 -8px;
    padding-top:12px;
}
.expsort .expsortlink {
    padding: 0 16px;
    border-radius: 20px;
    line-height: 30px;
    margin: 4px 0 0 4px;
    font-weight: 600;
    color: #808191;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}
.expsort .expsortlink.active {
    background: #191B20;
    color: #355DFF;
}
.expsortlink:hover{
  color:#FFFFFF;
  background:rgba(228, 228, 228, 0.1);
}

.apexcharts-xcrosshairs, .apexcharts-ycrosshairs{
  display:none;
}
.status {
    position: relative;
    padding-left: 18px;
    line-height: 16px;
    font-weight: 700;
}
.status.positive {
    color: #4FBF67;
}
.status:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 13px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% auto;
}
.status.positive:before {
    background: url("data:image/svg+xml,%3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.300049 6.7L1.70005 8.1L6.00005 3.8V16H8.00005V3.8L12.3 8.1L13.7 6.7L7.00005 0L0.300049 6.7Z' fill='%234FBF67'/%3E%3C/svg%3E%0A");
}
@media only screen and (max-width: 767px){
  .widgetsitem{
    max-width: 100%;
    margin: 0;
    margin-bottom:16px;
  }
  .widgetslist{
    margin:0;
    display:block;
  }
}
</style>
